import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { COWTUXProgressBarService } from '@cowtux/components/progress-bar/progress-bar.service';

@Component({
    selector     : 'cowtux-progress-bar',
    templateUrl  : './progress-bar.component.html',
    styleUrls    : ['./progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class COWTUXProgressBarComponent implements OnInit, OnDestroy
{
    diameter: number = 100; // Default size
    visible: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {COWTUXProgressBarService} _cowtuxProgressBarService
     */
    constructor(
        private _cowtuxProgressBarService: COWTUXProgressBarService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the progress bar service properties

        // Visible
        this._cowtuxProgressBarService.visible
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((visible) => {
                this.visible = visible;
            });

        // Optionally, if the service supports diameter, subscribe to it
        this._cowtuxProgressBarService.diameter
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((diameter) => {
                this.diameter = diameter;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

}
