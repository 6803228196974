import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class COWTUXProgressBarService {
    private _visibleSubject = new BehaviorSubject<boolean>(false);
    private _diameterSubject = new BehaviorSubject<number>(50); // Default diameter

    visible = this._visibleSubject.asObservable();
    diameter = this._diameterSubject.asObservable();

    // Methods to update the subjects
    show(): void {
        this._visibleSubject.next(true);
    }

    hide(): void {
        this._visibleSubject.next(false);
    }

    setDiameter(diameter: number): void {
        this._diameterSubject.next(diameter);
    }
}
