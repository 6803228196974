import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { COWTUXModule } from '@cowtux/cowtux.module';
import { COWTUXSharedModule } from '@cowtux/shared.module';
import { FuseProgressBarModule, COWTUXSidebarModule, COWTUXThemeOptionsModule } from '@cowtux/components';

import { cowtuxConfig } from 'app/cowtux-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { TableModule } from 'primeng/table';
import { ToastrModule } from 'ng6-toastr-notifications';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { MatDialogRef, MAT_DIALOG_DATA, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { NgxTimeSchedulerModule } from '../lib/ngx-time-scheduler/ngx-time-scheduler.module'
import { MomentUtcDateAdapter } from './services/moment-utc-date-adapter';
 const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule'
    },
    {
        path: '**',
        redirectTo: 'pages/auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
       
       
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            useHash: true
        }),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // COWTUX modules
        COWTUXModule.forRoot(cowtuxConfig),
        FuseProgressBarModule,
        COWTUXSharedModule,
        COWTUXSidebarModule,
        COWTUXThemeOptionsModule,
        NgxTimeSchedulerModule,
        // App modules
        LayoutModule,


        TableModule,
        ToastrModule.forRoot(),
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ],
})
export class AppModule {
}
